<template>
  <div class="my-page-message my-page" :class="{skeleton:!state.loaded}">
    <ul class="nav">
      <li class="nav-item" v-for="(m, idx) in state.menus" :key="idx">
        <router-link :to="m.path" :class="{'badge-point-soft':$route.path === m.path}" class="btn">{{ m.title }}</router-link>
      </li>
    </ul>
    <div class="content">
      <template v-if="state.messages.length">
        <ul class="tight font-sm">
          <li v-for="(m, idx) in state.messages" :key="idx" @click="open(m)">
            <span class="picture">
              <router-link @click.native.stop :to="`/channel/${getMemberSeq(m)}`" v-if="!(m.memberAuth && m.memberAuth.includes('ADMIN'))">
              <MemberPicture :memberSeq="getMemberSeq(m)" size="47"/>
                </router-link>
              <MemberPicture :memberSeq="getMemberSeq(m)" size="47" v-else/>
            </span>
            <div class="member bold">
              <span class="badge badge-secondary">{{ computedBadge }}</span>
              <span class="name font-sm">{{ !(m.memberAuth && m.memberAuth.includes("ADMIN")) ? m.memberName : "오마이컴퍼니" }}</span>
            </div>
            <div class="title">
              <span>{{ m.title }}</span>
            </div>
            <div class="date">{{ m.sendDate }}</div>
          </li>
        </ul>
        <div class="page">
          <Pagination :info="state.paginationInfo" :change="load"/>
        </div>
      </template>
      <NoData v-else/>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import router from "@/scripts/router";
import MemberPicture from "@/components/MemberPicture";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import NoData from "@/components/NoData";
import Pagination from "@/components/Pagination";

function Component(initialize) {
  this.name = "pageMyPageMessage";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Pagination, NoData, MemberPicture},
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "메시지");

      for (let i = 0; i < 3; i += 1) {
        state.messages.push({
          memberName: "Wait man",
          title: "Hello, We're sorry, but could you please wait a moment?",
          sendDate: "2021-12-09"
        });
      }

      load();
    });

    const state = reactive({
      loaded: false,
      args: {
        pageIndex: 1,
        pageSize: 10,
        pageUnit: 10
      },
      messages: [],
      paginationInfo: {},
      menus: [
        {title: "받은 메시지 (0)", path: "/mypage/message/receive"},
        {title: "보낸 메시지 (0)", path: "/mypage/message/send"},
        {title: "보관함 (0)", path: "/mypage/message/keep"},
      ],
    });

    const type = router.app.$route.path.endsWith("/receive") ? "inbox" : router.app.$route.path.endsWith("/send") ? "outbox" : "draft";

    const getMemberSeq = (message) => {
      if (type === "outbox") {
        return message.takeMemberSeq;
      }

      return message.sendMemberSeq;
    };

    const computedBadge = computed(() => {
      if (type === "outbox") {
        return "수신자";
      }

      return "발신자";
    });

    const open = (message) => {
      if (!state.loaded) {
        return;
      }

      store.commit("openModal", {
        name: "MessageDetail",
        params: {type, message},
        callback: "refresh"
      });
    };

    const load = (num) => {
      const url = "/api/messages/" + type;
      state.args.pageIndex = num || 1;
      window.scrollTo(0, 0);

      state.loaded = false;
      http.get(url, state.args).then(({data}) => {
        state.loaded = true;
        state.messages = data.body.messageList;
        state.paginationInfo = data.body.paginationInfo;
      });

      http.get("/api/messages/counts", state.args).then(({data}) => {
        state.menus[0].title = `받은 메시지 (${data.body.receive})`;
        state.menus[1].title = `보낸 메시지 (${data.body.send})`;
        state.menus[2].title = `보관함 (${data.body.keep})`;
      });
    };

    return {component, state, type, computedBadge, getMemberSeq, open, load};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-message {
  position: relative;

  .content {
    ul {
      li {
        position: relative;
        padding: $px25 $px25 $px25 $px76;
        margin-bottom: $px15;
        border: $px1 solid #eee;
        border-radius: $px4;

        .picture {
          position: absolute;
          top: $px25;
          left: $px15;
        }

        .member {
          margin-bottom: $px5;

          span {
            &:not(.img) {
              display: inline-block;
              vertical-align: middle;
              margin-right: $px7;
            }

            &.badge {
              padding-top: $px4;
              padding-bottom: $px4;
            }

            &.name {
              position: relative;
              top: $px-1;
            }
          }

          .img {
            width: $px16;
            height: $px16;
          }
        }

        .title {
          color: #767676;
        }

        .date {
          position: absolute;
          top: $px25;
          right: $px25;
          color: #767676;
        }

        &.empty {
          text-align: center;
        }
      }
    }

    .page {
      padding-top: $px15;
    }
  }

  &:not(.skeleton) {
    .content ul li:not(.empty) {
      cursor: pointer;
      transition: background 0.18s;

      &:hover {
        background: $colorBackground;
      }
    }
  }

  @media(max-width: 767px) {
    .content ul li {
      padding: $px20 $px20 $px20 $px76;

      .picture {
        top: $px20;
      }

      .date {
        position: static;
        margin-top: $px3;
      }
    }
  }

  &.skeleton {
    .nav li a {
      @include skeleton;
    }

    .content ul li {
      .picture .img, .member > span, .title > span, .date {
        @include skeleton;
      }

      .member > span.badge {
        display: none;
      }
    }
  }
}
</style>